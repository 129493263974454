import camelcaseKeys from 'camelcase-keys';
import { useInterval } from 'react-use';
import { createStore } from '~/store/createStore';
/**
 * Signalr 訂閱連線之中，用來儲存 OHLC 格式的 Store
 *
 * - 和 topic:bidask 以及 topic:tick 格式不相容
 */
export const useSignalrStoreValueOHLC = createStore((set, get) => {
    const value_ = {};
    // defaults interval function for  value updating by interval
    const updateIntervalDefaults = setInterval(() => {
        set(state => {
            state.value = { ...value_ };
        });
    }, 500);
    // initial value display ASAP
    setTimeout(() => {
        set(state => {
            state.value = { ...value_ };
        });
    }, 500);
    return {
        value: {},
        useGroupOHLC: (symbols) => {
            const a = symbols.map(s => get().value[s]);
            return a;
        },
        valueBuilder: data => {
            if ('symbol' in data && 'close' in data) {
                const data_ = camelcaseKeys(data);
                value_[data_.symbol] = {
                    ...value_[data_.symbol],
                    ...data_,
                };
            }
        },
        useValueUpdateInterval(intervalMillisecond) {
            clearInterval(updateIntervalDefaults);
            useInterval(() => {
                set(state => {
                    state.value = { ...value_ };
                });
            }, intervalMillisecond);
        },
    };
});
